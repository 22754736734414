import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './style.css'
import Home from './views/home'

import NotFound from './views/not-found'

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        
            <Route index element={<Home />} />
            <Route path="*" element={<NotFound />} />
  
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
